// Import filter converter functions
import geo from "./geo"
import lang from "./lang"
import audienceCredibility from "./audienceCredibility"

// Define the key and function mapping
const filterConverters = {
  geo: geo,
  audience_geo: geo,

  lang: lang,
  audience_lang: lang,

  audience_credibility: audienceCredibility,
}

/**
 * Convert the API filter object into UI filter object
 *
 * @param {String} key
 * @param {Object} value
 * @param {String} module
 * @returns {Object}
 */
export default async function filterConverter({ key, value, actions = [], module = "influencer-discovery" }) {
  // Get the converter function
  const converter = filterConverters[key]

  // If the converter function exists, call it
  if (converter) {
    return await converter({ key, value, actions, module })
  }

  // If the converter function does not exist
  return null
}
