// Import helper function
import dataHelper from "@/helpers/dataHelper"

/**
 * Convert the API filter object into UI filter object
 *
 * @param {String} key
 * @param {Object} value
 * @param {String} module
 * @returns {Object}
 */
export default async function lang({ key, value, module }) {
  // Load the languages data
  const languages = await dataHelper.loadData("languages.json")

  const kind = key === "lang" ? "influencer" : "audience"
  const useLabel = module === "influencer-discovery"
  const language = languages.find((search) => search.code === value.code)

  // If the language is not found, return null
  if (!language) {
    return null
  }

  const filter = {
    type: "language",
    data: {
      kind,
      color: "green lighten-5",
      icon: "translate",
      iconColor: "green",
      text: `${useLabel ? capitalizeString(kind) + " " : ""}Language: ${language.name}` + (kind === "audience" ? ` | >${value.weight * 100}%` : ""),
      inputs: {
        name: language.name,
        requestData: value,
      }
    }
  }

  // Unload the languages data
  dataHelper.unloadData("languages.json")

  // Return the filter object
  return filter
}