/**
 * Convert the API filter object into UI filter object
 *
 * @param {Object} value
 * @param {Array} actions
 * @returns {Object}
 */
export default async function audienceCredibility({ key, value, actions }) {
  // Get the action for this filter
  const action = actions.find((search) => search.filter === "audience_credibility")

  // Compute the label
  let label = '> ' + (value * 100) + '%'

  // If the value is set to (0.7)
  if (value === 0.7) {
    label = "High and Best"
  }

  // If the label is set to (0.4 and action is present)
  if (value === 0.4 && action.action === "not") {
    label = "Low"
  }

  const filter = {
    type: key,
    data: {
      color: "pink lighten-5",
      icon: "query_stats",
      iconColor: "pink",
      text: `Audience Credibility: ${label}`,
      inputs: {
        value
      }
    }
  }

  // Return the filter object
  return filter
}