// Import helper function
import dataHelper from "@/helpers/dataHelper"

/**
 * Convert the API filter object into UI filter object
 *
 * @param {String} key
 * @param {Object} value
 * @param {String} module
 * @returns {Object}
 */
export default async function geo({ key, value, module }) {
  // Load the locations data
  const locations = await dataHelper.loadData("locations.json")

  const kind = key === "geo" ? "influencer" : "audience"
  const useLabel = module === "influencer-discovery"
  const location = locations.find((search) => search.id === value.row_id)

  // If the location is not found, return null
  if (!location) {
    return null
  }

  const filter = {
    type: "location",
    data: {
      kind,
      color: "pink lighten-5",
      icon: "flag",
      iconColor: "pink",
      text: `${useLabel ? capitalizeString(kind) + " " : ""}Location: ${location.name}` + (kind === "audience" ? ` | >${value.weight * 100}%` : ""),
      inputs: {
        name: location.name,
        requestData: value,
      }
    }
  }

  // Unload the locations data
  dataHelper.unloadData("locations.json")

  // Return the filter object
  return filter
}