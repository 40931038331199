/**
 * Get response data using API
 *
 * @param {String}
 * @returns {Object|Null}
 */
export default async function (key) {
  // Try running the request
  try {
    // Use helper function
    const response = await axios({
      url: `/api/response/${window.profileData.id}/${key}`,
      method: "POST"
    })

    // If found, respond with it
    return response.data
  }
  // Catch an error
  catch (error) {
    // Use helper function
    logger({ type: "ResponseHelper/Get Error", error })

    // Return null
    return null
  }
}